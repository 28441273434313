exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-home-js": () => import("./../../../src/pages/app/home.js" /* webpackChunkName: "component---src-pages-app-home-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-app-profile-js": () => import("./../../../src/pages/app/profile.js" /* webpackChunkName: "component---src-pages-app-profile-js" */),
  "component---src-pages-app-statistics-js": () => import("./../../../src/pages/app/statistics.js" /* webpackChunkName: "component---src-pages-app-statistics-js" */),
  "component---src-pages-catalogo-js": () => import("./../../../src/pages/catalogo.js" /* webpackChunkName: "component---src-pages-catalogo-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pedidos-js": () => import("./../../../src/pages/pedidos.js" /* webpackChunkName: "component---src-pages-pedidos-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-saentech-pro-audiovisual-js": () => import("./../../../src/pages/saentech-pro/audiovisual.js" /* webpackChunkName: "component---src-pages-saentech-pro-audiovisual-js" */),
  "component---src-pages-saentech-pro-equipament-js": () => import("./../../../src/pages/saentech-pro/equipament.js" /* webpackChunkName: "component---src-pages-saentech-pro-equipament-js" */),
  "component---src-pages-saentech-pro-js": () => import("./../../../src/pages/saentech-pro.js" /* webpackChunkName: "component---src-pages-saentech-pro-js" */),
  "component---src-pages-saentech-pro-network-js": () => import("./../../../src/pages/saentech-pro/network.js" /* webpackChunkName: "component---src-pages-saentech-pro-network-js" */),
  "component---src-pages-saentech-pro-software-js": () => import("./../../../src/pages/saentech-pro/software.js" /* webpackChunkName: "component---src-pages-saentech-pro-software-js" */),
  "component---src-pages-subvenciones-js": () => import("./../../../src/pages/subvenciones.js" /* webpackChunkName: "component---src-pages-subvenciones-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

